import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createAIMTInstructionAsync = createAsyncThunk(
  "admin/createAIMTInstruction",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/createAIMTInstruction`,
      payload,
      toolkit
    );
  }
);

export const getAllAIMTInstructionAsync = createAsyncThunk(
  "admin/getAllAIMTInstruction",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllAIMTInstruction?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const getAIMTInstructionByIdAsync = createAsyncThunk(
  "admin/getAIMTInstructionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAIMTInstructionById/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateAIMTInstructionByIdAsync = createAsyncThunk(
  "admin/updateAIMTInstructionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateAIMTInstructionById`,
      payload,
      toolkit
    );
  }
);

export const deleteAIMTInstructionByIdAsync = createAsyncThunk(
  "admin/deleteAIMTInstructionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteAIMTInstructionById/${payload}`,
      [],
      toolkit
    );
  }
);
