import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const addAIMTSyllabusAsync = createAsyncThunk(
  "admin/addAIMTSyllabus",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addAIMTSyllabus`, payload, toolkit);
  }
);

export const getAllAIMTSyllabusAsync = createAsyncThunk(
  "admin/getAllAIMTSyllabus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllAIMTSyllabus?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}&classes=${
        payload.classes || ""
      }&subject=${payload.subject || ""}&chapter=${
        payload.chapter || ""
      }&status=${payload.status || ""}`,
      [],
      toolkit
    );
  }
);

export const getAIMTSyllabusByIdAsync = createAsyncThunk(
  "admin/getAIMTSyllabusById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAIMTSyllabusById/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateAIMTSyllabusAsync = createAsyncThunk(
  "admin/updateAIMTSyllabus",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateAIMTSyllabus`, payload, toolkit);
  }
);

export const deleteAIMTSyllabusByIdAsync = createAsyncThunk(
  "admin/deleteAIMTSyllabusById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteAIMTSyllabusById/${payload}`,
      [],
      toolkit
    );
  }
);
