import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addAIMTSyllabusAsync,
  getAllAIMTSyllabusAsync,
  getAIMTSyllabusByIdAsync,
  updateAIMTSyllabusAsync,
  deleteAIMTSyllabusByIdAsync,
} from "./syllabusmocktest.async";

const initialState = {
  syllabusMockTestLoader: false,
  getAllMockTestSyllabus: [],
  syllabusMockTestCreate: [],
  syllabusMockTestId: [],
  syllabusMockTestUpdate: [],
  syllabusMockTestDelete: [],
};

export const syllabusmocktestSlice = createSlice({
  name: "syllabus",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllAIMTSyllabusAsync.pending,
        addAIMTSyllabusAsync.pending,
        getAIMTSyllabusByIdAsync.pending,
        updateAIMTSyllabusAsync.pending,
        deleteAIMTSyllabusByIdAsync.pending
      ),
      (state) => {
        state.syllabusMockTestLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAIMTSyllabusAsync.fulfilled),
      (state, action) => {
        state.syllabusMockTestLoader = false;
        state.getAllMockTestSyllabus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addAIMTSyllabusAsync.fulfilled),
      (state, action) => {
        state.syllabusMockTestLoader = false;
        state.syllabusMockTestCreate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTSyllabusByIdAsync.fulfilled),
      (state, action) => {
        state.syllabusMockTestLoader = false;
        state.syllabusMockTestId = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteAIMTSyllabusByIdAsync.fulfilled),
      (state, action) => {
        state.syllabusMockTestLoader = false;
        state.syllabusMockTestDelete = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateAIMTSyllabusAsync.fulfilled),
      (state, action) => {
        state.syllabusMockTestLoader = false;
        state.syllabusMockTestUpdate = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllAIMTSyllabusAsync.rejected,
        addAIMTSyllabusAsync.rejected,
        getAIMTSyllabusByIdAsync.rejected,
        updateAIMTSyllabusAsync.rejected,
        deleteAIMTSyllabusByIdAsync.rejected
      ),
      (state, action) => {
        state.syllabusMockTestLoader = false;
      }
    );
  },
  reducers: {
    emptySyllabusMockTest: (state) => initialState,
  },
});

export const { emptySyllabusMockTest } = syllabusmocktestSlice.actions;

export default syllabusmocktestSlice.reducer;
