import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createAIMTestAsync = createAsyncThunk(
  "admin/createAIMTest",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createAIMTest`, payload, toolkit);
  }
);

export const getAllAIMTestAsync = createAsyncThunk(
  "admin/getAllAIMTest",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllAIMTest?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

export const getAIMTByIdAsync = createAsyncThunk(
  "admin/getAIMTById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getAIMTById/${payload}`, [], toolkit);
  }
);

export const updateAIMTByIdAsync = createAsyncThunk(
  "admin/updateAIMTById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateAIMTById`, payload, toolkit);
  }
);

export const deleteAIMTByIdAsync = createAsyncThunk(
  "admin/deleteAIMTById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteAIMTById/${payload}`,
      [],
      toolkit
    );
  }
);
