import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createAIMTInstructionAsync,
  getAIMTInstructionByIdAsync,
  getAllAIMTInstructionAsync,
  updateAIMTInstructionByIdAsync,
  deleteAIMTInstructionByIdAsync,
} from "./instructionmocktest.async";

const initialState = {
  instructionMockTestLoader: false,
  getAllMockTestInstrctions: [],
  instructionMockTestCreate: [],
  instructionMockTestId: [],
  instructionMockTestUpdate: [],
  instructionMockTestDelete: [],
};

export const instructionmocktestSlice = createSlice({
  name: "instructions",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAIMTInstructionByIdAsync.pending,
        createAIMTInstructionAsync.pending,
        getAllAIMTInstructionAsync.pending,
        updateAIMTInstructionByIdAsync.pending,
        deleteAIMTInstructionByIdAsync.pending
      ),
      (state) => {
        state.instructionMockTestLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTInstructionByIdAsync.fulfilled),
      (state, action) => {
        state.instructionMockTestLoader = false;
        state.instructionMockTestId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createAIMTInstructionAsync.fulfilled),
      (state, action) => {
        state.instructionMockTestLoader = false;
        state.instructionMockTestCreate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAIMTInstructionAsync.fulfilled),
      (state, action) => {
        state.instructionMockTestLoader = false;
        state.getAllMockTestInstrctions = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteAIMTInstructionByIdAsync.fulfilled),
      (state, action) => {
        state.instructionMockTestLoader = false;
        state.instructionMockTestDelete = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateAIMTInstructionByIdAsync.fulfilled),
      (state, action) => {
        state.instructionMockTestLoader = false;
        state.instructionMockTestUpdate = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAIMTInstructionByIdAsync.rejected,
        createAIMTInstructionAsync.rejected,
        getAllAIMTInstructionAsync.rejected,
        updateAIMTInstructionByIdAsync.rejected,
        deleteAIMTInstructionByIdAsync.rejected
      ),
      (state, action) => {
        state.instructionMockTestLoader = false;
      }
    );
  },
  reducers: {
    emptyInstructionMockTest: (state) => initialState,
  },
});

export const { emptyInstructionMockTest } = instructionmocktestSlice.actions;

export default instructionmocktestSlice.reducer;
